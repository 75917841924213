<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Remolque">
          <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @list="list" :actions="['new','save','list']" />
          <div class="grid formgrid p-fluid">
            <div class="col-12">
              <TabPanel>
                <Fieldset :toggleable="true" legend="Informacion General">
                  <div class="p-fluid formgrid grid">
                    <FormDropdownComplex :labelInOption="'c_SubTipoRem - name'" :labelInValue="'c_SubTipoRem - name'" :wrapperClass="'field col-6'" label="Tipo de Remolque" v-model="entity.trailer_code" :options="c_SubTipoRem" optionLabel="c_SubTipoRem" optionValue="c_SubTipoRem" :tooltip="'Se obtiene del catalogo SAT c_SubTipoRem'"/>
                    <FormInputText wrapperClass="field col-4" label="Placa" :valid="validate.validations.license_plate" v-model="entity.license_plate" :tooltip="'Ingresar la placa del remolque o semirremolque, sin guiones ni espacios'"/>
                  </div>
                </Fieldset>
              </TabPanel>
            <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" 
        :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />
        <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import {HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast, satCatalogo,} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { ConsignmentNoteTrailer } from '../../../models/cfdi40/ConsingmentNoteTrailer';
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
export default {
  mixins: [Session],
  props: {
    modal: null,
    id: null
  },
  data() {
    return {
      entity: null,
      entities: [],
      c_SubTipoRem: [],
      deleteDialog: false,
      rules: [
      new Rule({ name: "trailer_code" }),
        new Rule({ name: "license_plate" }),
      ],
      validate: {
        valid: false,
        validations: {
          trailer_code: null,
          license_plate: null,
        },
      },
      headers: [
        new HeaderGrid("Codigo Remolque", "trailer_code"),
        new HeaderGrid("Placa", "license_plate"),
      ],
      loading: false,
    };
  },
  components: { FormDropdownComplex, Loader, FormInputText, BasicFormToolbar, BasicDatatable, DeleteDialog },
  created() {
    this.entity = new ConsignmentNoteTrailer(this.session);
  },
  async mounted() {
    await this.refresh();
    this.c_SubTipoRem = await satCatalogo(20);
   },   
  methods: {
    deleted() {
        this.deleteEntity();
    },
    openNew() {
      this.entity = new ConsignmentNoteTrailer(this.session);
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
       this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', entity);
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.$emit('save', entity);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new ConsignmentNoteTrailer(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      console.log(entity);
      this.entity.id = entity.id;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new ConsignmentNoteTrailer(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        
        if (this.id) {
          this.entity.id = this.id;
          let entity = await this.entity.retrieve();
          this.entity = fillObject(this.entity, entity);
        }
        console.log(this.entity);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async list() {
        this.loading = true;
        try {
          this.entity.branch = this.session.branch;
          this.entity.company = this.session.company;
          this.entities = await new ConsignmentNoteTrailer().data({
            branch: this.session.branch,
            company: this.session.company,
            trailer_code: this.entity.trailer_code,
            license_plate: this.entity.license_plate
          });
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },
  },
  computed: {
  }
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

</style>
